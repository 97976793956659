/**
 * Do not edit directly
 * Generated on Wed, 09 Apr 2025 15:45:18 GMT
 */

:root, ::before, ::after {
  --color-gray-90: #F9F9F9;
  --color-gray-100: #F2F4F5;
  --color-gray-200: #E2E5E7;
  --color-gray-300: #C8CACC;
  --color-gray-400: #7C7E80;
  --color-gray-500: #616466;
  --color-gray-600: #17181c;
  --color-blue-100: #C2E3FF;
  --color-blue-200: #A8D7FF;
  --color-blue-300: #92C8F6;
  --color-blue-400: #70ACDF;
  --color-blue-500: #528DBF;
  --color-blue-600: #3D6F99;
  --color-blue-700: #2F5575;
  --color-blue-800: #1A3042;
  --color-indigo-100: #CDDAF9;
  --color-indigo-200: #9FB8F4;
  --color-indigo-300: #7197EF;
  --color-indigo-400: #4375EA;
  --color-indigo-500: #1955E1;
  --color-indigo-600: #1444B3;
  --color-green-100: #BCEBE6;
  --color-green-200: #95DFD8;
  --color-green-300: #6ED3C9;
  --color-green-400: #47C8BB;
  --color-green-500: #33A99D;
  --color-green-600: #278279;
  --color-red-50: #FFEDEF;
  --color-red-90: #FFDADE;
  --color-red-100: #FFA3AD;
  --color-red-200: #FF8795;
  --color-red-300: #FF576B;
  --color-red-400: #FF374E;
  --color-red-500: #EB001B;
  --color-red-600: #D8061F;
  --color-red-700: #8a000d;
  --color-white: #fff;
  --color-black: #000;
  --color-gold-100: #FBF7F1;
  --color-gold-200: #F7EFE2;
  --color-gold-300: #EFE0C6;
  --color-gold-400: #E7D0A9;
  --color-gold-500: #D7B170;
  --color-gold-600: #875D15;
  --color-teal-100: #EFFEFF;
  --color-teal-200: #DBFAFD;
  --color-teal-300: #B1EAEF;
  --color-teal-400: #78D2DB;
  --color-teal-500: #2CA9B4;
  --color-teal-600: #157E88;
  --color-bronze-100: #E7E4DF;
  --color-bronze-200: #D1CCC2;
  --color-bronze-300: #BBB4A5;
  --color-bronze-400: #A59B88;
  --color-bronze-500: #8E836C;
  --color-bronze-600: #716856;
  --color-purple-100: #E7BEEF;
  --color-purple-200: #D895E4;
  --color-purple-300: #C96CDA;
  --color-purple-400: #BB43D0;
  --color-purple-500: #9F2DB4;
  --color-purple-600: #7C238C;
  --rounded-default: 3px;
  --rounded-button: 3px;
  --rounded-badge: 3px;
  --font-family-body: Inter;
  --font-family-headline: Space Grotesk;
  --font-size-root: 16px;
  --font-size-2xs: 0.5rem;
  --font-size-xs: 0.625rem;
  --font-size-sm: 0.75rem;
  --font-size-body: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.75rem;
  --font-size-4xl: 2rem;
  --font-size-5xl: 2.5rem;
  --font-size-6xl: 3.5rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --leading-normal: 1.5;
  --leading-tight: 1.1;
  --leading-snug: 1.25;
  --leading-relaxed: 1.75;
  --leading-loose: 2;
  --leading-body: 1.5;
  --shadow-100: 0 1px 2px 0 rgba(0,0,0,0.25);
  --shadow-200: 0 4px 8px -2px rgba(0,0,0,0.25);
  --shadow-300: 0 8px 16px -4px rgba(0,0,0,0.25);
  --shadow-400: 0 12px 24px -6px rgba(0,0,0,0.25);
  --shadow-500: 0 20px 32px -8px rgba(0,0,0,0.25);
  --shadow-100-up: 0 -1px 4px 0 rgba(0,0,0,0.25);
  --shadow-200-up: 0 -4px 8px -2px rgba(0,0,0,0.25);
  --shadow-300-up: 0 -8px 16px -4px rgba(0,0,0,0.25);
  --shadow-400-up: 0 -12px 24px -6px rgba(0,0,0,0.25);
  --shadow-500-up: 0 -20px 32px -8px rgba(0,0,0,0.25);
  --size-base: 4px;
  --max-width-content: 1024px;
  --cta-height: 40px;
  --rounded-pill: 100px;
  --spacing-0: 0px;
  --spacing-12: 12px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-56: 56px;
  --spacing-vertical-mobile-sm: 12px;
  --spacing-vertical-mobile-md: 32px;
  --spacing-vertical-mobile-lg: 40px;
  --spacing-vertical-desktop-sm: 12px;
  --spacing-vertical-desktop-md: 32px;
  --spacing-vertical-desktop-lg: 56px;
}
